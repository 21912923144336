import React from 'react';
import { useLocation } from "@reach/router"

import { AppContext } from '../lib/app-context';

import MyProjects from './MyProjects';
import SideBarMenu from '../ui-components/SideBarMenu';
import YouTubeGallery from './trends';



const TABS = {
  PROJECTS: 0,
  CONTENT: 1,
  TRENDS: 2,
}

export default function index() {
  const { user, config } = React.useContext(AppContext)
  const location = useLocation()

  function onAddImage(img) {
    images.current.push(img);
  }

  function onSelect(sequenceSid) {
    images.current.forEach(img => img.src = '');

    if (status) {
      status.sid = sequenceSid
    } else {
      stat = new Status(sequenceSid, user.sid, user.orgSid);
      setStatus(stat);

    }
  }


  return  null
 
}